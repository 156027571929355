(function () {
    'use strict';

    angular
        .module('smartermail')
        .controller('MainMenuController', MainMenuController);

    function MainMenuController($rootScope, $scope, $log, $mdDialog, $http, $filter, $sanitize, $timeout,
        coreData, coreDataMail, coreDataSettings, simpleXmppService, AuthenticationService, coreLicensing,
        userDataService, notifications, errorHandling, browserNotifications, popupService) {

        // Data
        var vm = this;
        $scope.coreData = coreData;
        $scope.chatUnreadMessages = 0;
        vm.chatUnreadMessages = 0;
        $scope.chatStatus = simpleXmppService.parameters.status;
        vm.chatStatus = $scope.chatStatus;
        $scope.picSource = $scope.coreData.user.pictureUrl;
        $scope.picName = $scope.coreData.user.username;
        $scope.features = null;
        vm.features = null;
        $scope.userNotificationCount = 0;
        $scope.domainNotificationCount = 0;
        $scope.systemNotificationCount = 0;
        vm.userNotificationCount = 0;
        vm.domainNotificationCount = 0;
        vm.systemNotificationCount = 0;
        $scope.unreadMessages = 0;
        vm.unreadMessages = 0;
        vm.customHelpInfo = coreData.customHelpInfo;
        vm.avatarUrl = '';
        vm.avatarName = '';
        vm.userNotificationCountIndicator = vm.userNotificationCount + vm.domainNotificationCount;
        vm.edition = 1;

        // Functions
        $scope.setChatStatus = setChatStatus;
        $scope.Logout = Logout;
        vm.Logout = Logout;
        $scope.openHelp = openHelp;
        vm.openHelp = openHelp;
        vm.openNotifications = openNotifications;

        function openNotifications() {
            window.open((stSiteRoot || '/') + "interface/root#/popout/notifications", "notifications", 'resizable=1, ' + popupService.dimensions.notification);
        }

        $scope.$on('user-settings:changed', function () {
            vm.avatarName = coreData.user.displayName;
            vm.avatarUrl = coreData.user.pictureUrl;
        });

        // Initialization
        coreData.init()
            .then(function () {
                vm.customHelpInfo = coreData.customHelpInfo;
                vm.avatarUrl = coreData.user.pictureUrl;
                vm.avatarName = coreData.user.displayName;
                coreDataSettings
                    .init()
                    .then(onInitialized, errorHandling.report);
            }, errorHandling.report);

        function onInitialized() {
            initDiskUsageKnobChart();
            hookEvents();
            loadFeatures();
        }

        // Implementation
        recalculateLicense();
        coreLicensing.watchForChanges($scope, recalculateLicense);

        function recalculateLicense() {
            vm.edition = coreLicensing.edition;
            if (vm.edition === coreLicensing.EDITION_PROFESSIONAL) {
                if (vm.features) {
                    vm.features.enableWebConferencing = false;
                    vm.features.enableChat = false;
                }
            }
        }

        function loadFeatures() {
            userDataService
                .init()
                .then(function (success) {
                    $scope.features = userDataService.user.settings.features;
                    vm.features = $scope.features;
                    recalculateLicense();
                });

            notifications.getNotificationCount()
                .then(function (data) {
                    $scope.userNotificationCount = data.userCount;
                    vm.userNotificationCount = data.userCount;
                    $scope.domainNotificationCount = data.domainCount;
                    vm.domainNotificationCount = data.domainCount;
                    $scope.systemNotificationCount = data.systemCount;
                    vm.systemNotificationCount = data.systemCount;
                    vm.userNotificationCountIndicator = vm.userNotificationCount + vm.domainNotificationCount;
                    $scope.$evalAsync();
                });
        }

        function hookEvents() {
            $scope.$on("xmpp.property-changed", onXmppPropertyChanged);
            $scope.$on('signalR.mailHub.client.notificationChanged', onNotificationChanged);
            $scope.$on('signalR.mailHub.client.eventNotification', onEventNotification);
            $scope.$on('signalR.mailHub.client.appointmentReminderNotification', onAppointmentReminderNotification);
            $scope.$on('signalR.mailHub.client.mailAdded', onMailCountChanged);
            $scope.$on('signalR.mailHub.client.mailRemoved', onMailCountChanged);
            $scope.$on('signalR.mailHub.client.mailModified', onMailCountChanged);
            $scope.$on('signalR.mailHub.client.folderChange', onMailCountChanged);
            $scope.$on('signalR.mailHub.client.messageFlagChange', onMailCountChanged);
            $scope.$on('mail:unreadCountChanged', onMailCountChanged);
        }

        function onMailCountChanged() {
            $scope.unreadMessages = coreDataMail.getUnreadCount();
            $rootScope.unreadEmailCount = $scope.unreadMessages;
            $rootScope.$broadcast('unreadMailChanged');
            vm.unreadMessages = $scope.unreadMessages;
        }

        function onNotificationChanged(event, data) {
            if (data.userCount !== null && data.userCount !== undefined) {
                $scope.userNotificationCount = data.userCount;
                vm.userNotificationCount = data.userCount;
            }
            if (data.domainCount !== null && data.domainCount !== undefined) {
                $scope.domainNotificationCount = data.domainCount;
                vm.domainNotificationCount = data.domainCount;
            }
            if (data.systemCount !== null && data.systemCount !== undefined) {
                $scope.systemNotificationCount = data.systemCount;
                vm.systemNotificationCount = data.systemCount;
            }
            vm.userNotificationCountIndicator = vm.userNotificationCount + vm.domainNotificationCount;
            $scope.$evalAsync();
        }

        function onEventNotification(event, data) {
            var options = {
                body: $(data.Text).text(),
                icon: (stSiteRoot || '/') + 'interface/img/notifications/alert.png',
                timeout: 5000,
                tag: data.Guid
            };
            options.notifyClick = function (e) {
                window.open((stSiteRoot || '/') + "interface/root#/popout/notifications", "notifications", 'resizable=1, ' + popupService.dimensions.notification);
            }
            browserNotifications.show(data.subject, options);
        }

        function onAppointmentReminderNotification(event, data) {
            var options = {
                body: data.text,
                icon: (stSiteRoot || '/') + 'interface/img/notifications/calendar.png',
                timeout: 5000,
                tag: data.guid
            };
            options.notifyClick = function (e) {
                window.open((stSiteRoot || '/') + "interface/root#/popout/notifications", "notifications", 'resizable=1, ' + popupService.dimensions.notification);
            }
            if (coreDataSettings.userSettings.notifyOnCalendarReminders)
                browserNotifications.show(data.subject, options);
        }

        function onXmppPropertyChanged(event, args) {
            if (args.hasOwnProperty("status")) {
                $scope.chatStatus = args.status;
                vm.chatStatus = $scope.chatStatus;
            }
            if (args.hasOwnProperty("unreadCount")) {
                $scope.chatUnreadMessages = args.unreadCount;
                vm.chatUnreadMessages = $scope.chatUnreadMessages;
            }
            $scope.$evalAsync();
        }

        function setChatStatus(newStatus) {
            simpleXmppService.setStatus(newStatus);
        }

        function Logout() {
            AuthenticationService.Logout("main menu controller (Logout)");
        }

        function openHelp(pagesrc) {
            let page = pagesrc;
            if (!page && $rootScope.$state.current.name && $rootScope.$state.current.name.startsWith("index.reports."))
                page = $rootScope.$state.current.helpid;
            if (!page)
                page = $rootScope.$state.current.name;

            var version = stProductVersion;
            const url = "https://help.smartertools.com/smartermail/current/?page=" + encodeURIComponent(page) + "&v=" + version;
            console.log('NAVIGATING TO HELP PAGE: ' + url);
            window.open(url, "help");
        }

        $scope.openAbout = function (ev) {
            $mdDialog.show({
                controller: "aboutSmartermailController",
                controllerAs: "ctrl",
                templateUrl: "app/root/modals/about.dlg.html",
                targetEvent: ev
            })
                .then(function (success) { }, function (cancel) { });
        }
        vm.openAbout = $scope.openAbout;
    }

    function initDiskUsageKnobChart() {
        //#region Knob Data/UI Options
        /*Behaviors
        min : min value (default=0)
        max : max value (default=100)
        step : step size (default=1)
        angleOffset : starting angle in degrees (default=0)
        angleArc : arc size in degrees (default=360)
        stopper : stop at min & max on keydown/mousewheel (default=true)
        readOnly : disable input and events (default=false)
        rotation : direction of progression (default=clockwise)

        UI
        cursor : display mode “cursor”, cursor size could be changed passing a numeric value to the option, default width is used when passing boolean value “true” (default=gauge)
        thickness : gauge thickness
        lineCap : gauge stroke endings (default=butt, round=rounded line endings)
        width : dial width
        height : dial height
        displayInput : display input (default=true)
        displayPrevious : displays the previous value with transparency (default=false)
        fgColor : foreground color
        inputColor : input value (number) color
        font : font family
        fontWeight : font weight
        bgColor : background color*/
        //#endregion

        $(function () {
            $(".dial").knob({
                "min": 0,
                "max": 100,
                "readOnly": true,
                "fgColor": "#FAD122",
                "bgColor": "#465257",
                "inputColor": "#DEDEDD",
                "fontWeight": "400",
                "width": 120,
                "height": 120,
                "thickness": .49,
                "rotation": 'anticlockwise',
                'format': function (value) {
                    return value + '%';
                }
            });
        });
    }

})();