(function () {

	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminManageController", sysAdminManageController);

	function sysAdminManageController($rootScope, $scope, $translate, $http, $q, $state, $mdDialog, $filter, $compile, toaster,
		successHandling, NgTableParams, gridCheckboxes, coreData, errorHandling, coreDataFileStorage, coreDataSysAdminSettings, coreLicensing,
		claimsService) {

		// Variables
		var vm = this;
		vm.searchParams = { skip: 0, take: 0, search: null, sortField: null, sortDescending: false };
		vm.defenderWarningAction = function () { window.open("http://portal.smartertools.com/kb/a3249/add-exceptions-to-windows-defender.aspx"); }
		vm.tableParams = new NgTableParams(
			{
				sorting: { idnName: "asc" },
				count: 25
			}, {
			getData: queryData,
			counts: $rootScope.commonTableCounts
		});
		vm.checkboxes = gridCheckboxes.init("name");
		vm.checkboxes.table = vm.tableParams;
		vm.domains = [];
		vm.coreDataSysAdminSettings = coreDataSysAdminSettings;
		vm.showWindowsDefenderWarning = false;
		vm.isInitialized = false;
		$scope.domainStr = $translate.instant("DOMAIN");
		$scope.statusStr = $translate.instant("STATUS");
		$scope.userCountStr = $translate.instant("USERS");
		$scope.aliasCountStr = $translate.instant("ALIASES");
		$scope.listCountStr = $translate.instant("MAILING_LISTS");
		$scope.easMailboxCountStr = $translate.instant("EAS_MAILBOXES");
		$scope.mapiEwsMailboxCountStr = $filter("htmlUnescape")($translate.instant("MAPI_EWS_MAILBOXES"));
		$scope.domainUsageStr = $translate.instant("DISK_USAGE");
		$scope.messageArchivingStr = $translate.instant("MESSAGE_ARCHIVING");
		$scope.certificatesStr = $translate.instant("SSL_TLS_CERTIFICATES");
		vm.canManage = false;
		vm.underFifteenDays = false;
		vm.detachingDomainNames = [];
		vm.seenWhatsNew = [];

		// Functions
		vm.newDomain = newDomain;
		vm.deleteItems = deleteItems;
		vm.editDomain = editDomain;
		vm.contextMenuGridItem = contextMenuGridItem;
		vm.exportCsv = exportCsv;
		vm.exportUsers = exportUsers;
		vm.massEmail = massEmail;
		vm.massReminder = massReminder;
		vm.searchItems = searchItems;
		vm.restore = restore;
		vm.attachDomain = attachDomain;
		vm.reloadDomain = reloadDomain;
		vm.enableDomain = enableDomain;
		vm.detachDomain = detachDomain;

		activate();

		//////////////////

		function activate() {
			coreData.init()
				.then(onInitSuccess, errorHandling.report);

			function onInitSuccess() {
				vm.isInitialized = true;
				vm.hasEnterpriseFunctionality = coreLicensing.hasEnterpriseFunctionality;
				var promises = [
					$http.get("~/api/v1/settings/sysadmin/license-notifications"),
					$http.get("~/api/v1/settings/sysadmin/user-login")];
				$q.all(promises)
					.then(onSuccess, errorHandling.report);

				function onSuccess(results) {
					onLicenseNotificationsSuccess(results[0]);
					vm.canManage = results[1].data.systemLogin.allowImpersonation;
					vm.seenWhatsNew = results[1].data.systemLogin.sysAdminSeenWhatsNew;
					var keyExist = ("systemAdmin" in vm.seenWhatsNew);
					if (keyExist) {
						var versionOverride = localStorage.getItem("FeatureVersionOverride");
						var shouldShow = versionOverride === null ? stProductVersion.split('.')[2] > vm.seenWhatsNew["systemAdmin"] : true;
						if (shouldShow) {
							var route = `~/api/v1/settings/sysadmin/new-features/SystemAdmin${versionOverride === null ? "" : "/" + versionOverride}`
							$http.get(route).then(onFeaturesLoaded, function () { });
						}
					} else {
						$http.get('~/api/v1/settings/sysadmin/new-features/SystemAdmin').then(onFeaturesLoaded, function () { });
					}
				}
			}

			function onLicenseNotificationsSuccess(result) {
				vm.showOverLicenseLimit = result.data.isOverLicenseLimit;
				var isFreeEdition = result.data.isFreeEdition;
				var isUpgradeProtectionExpired = result.data.isUpgradeProtectionExpired;
				var isUpgradeProtectionExpiringSoon = result.data.isUpgradeProtectionExpiringSoon;
				var daysUntilUpgradeProtectionExpires = result.data.daysUntilUpgradeProtectionExpires;
				var isTrialLicenseExpiringSoon = result.data.isTrialLicenseExpiringSoon;
				var daysUntilTrialExpires = result.data.daysUntilTrialExpires;

				if (isTrialLicenseExpiringSoon) {
					vm.showLicenseWarningBar = true;
					vm.licenseWarningMessage = $filter("translate")("LICENSE_WARNING_TRIAL_EXPIRING", { x: daysUntilTrialExpires });
					vm.underFifteenDays = daysUntilTrialExpires <= 15;
					vm.licenseWarningButton2Text = "PURCHASE";
					vm.licenseWarningAction2 = function () { window.open("https://www.smartertools.com/forwarder/smartermail/16/purchase"); }
				}
				else if (isUpgradeProtectionExpired) {
					vm.showLicenseWarningBar = true;
					vm.licenseWarningMessage = $filter("translate")("LICENSE_WARNING_UP_EXPIRED");
					vm.underFifteenDays = true;
					vm.licenseWarningButton1Text = "MORE_INFO";
					vm.licenseWarningAction1 = function () { window.open("https://www.smartertools.com/forwarder/smartermail/16/up-info"); }
					vm.licenseWarningButton2Text = "RENEW";
					vm.licenseWarningAction2 = function () { window.open("https://www.smartertools.com/forwarder/smartermail/16/up-renew"); }
				}
				else if (isUpgradeProtectionExpiringSoon) {
					vm.showLicenseWarningBar = true;
					vm.licenseWarningMessage = $filter("translate")("LICENSE_WARNING_UP_EXPIRING", { x: daysUntilUpgradeProtectionExpires });
					vm.underFifteenDays = daysUntilUpgradeProtectionExpires <= 15;
					vm.licenseWarningButton1Text = "MORE_INFO";
					vm.licenseWarningAction1 = function () { window.open("https://www.smartertools.com/forwarder/smartermail/16/up-info"); }
					vm.licenseWarningButton2Text = "RENEW";
					vm.licenseWarningAction2 = function () { window.open("https://www.smartertools.com/forwarder/smartermail/16/up-renew"); }
				}
				else if (isFreeEdition) {
					vm.showLicenseWarningBar = true;
					vm.licenseWarningMessage = $filter("translate")("LICENSE_WARNING_FREE_EDITION");
					vm.licenseWarningButton1Text = "PURCHASE";
					vm.licenseWarningAction1 = function () { window.open("https://www.smartertools.com/forwarder/smartermail/16/purchase"); }
					vm.licenseWarningButton2Text = "ACTIVATE";
					vm.licenseWarningAction2 = function () { $state.go("index.sysadmin-settings.activation"); }
				}

				setTimeout(function () {
					let hideDetailedLogWarning = localStorage.getItem("hideDetailedLogWarning") == "True";
					let hideDebugLogWarning = localStorage.getItem("hideDebugLogWarning") == "True";
					vm.showWindowsDefenderWarning = vm.coreDataSysAdminSettings.settings.globalMailSettings.windowsDefenderExceptionsMissing;
					vm.secondsOffOfOfficialUtcTime = vm.coreDataSysAdminSettings.settings.globalMailSettings.secondsOffOfOfficialUtcTime;
					vm.showDebugLoggingWarning = vm.coreDataSysAdminSettings.settings.globalMailSettings.logSettings.showDebugLoggingWarning;
					vm.showEASAllocationWarning = vm.coreDataSysAdminSettings.settings.globalMailSettings.showEASAllocationWarning;
					vm.showDetailedProtocolLogWarning =
						vm.coreDataSysAdminSettings.settings.globalMailSettings.logSettings.showDetailedProtocolLogWarning;
					vm.showCertificateValidityError = vm.coreDataSysAdminSettings.settings.globalMailSettings.showCertificateValidityError;
					if (!$rootScope.showWarnings) {
						if (vm.showCertificateValidityError) {
							window.certificateErrorToast = toaster.pop({
								type: "error",
								title: null,
								body: $translate.instant("INVALID_CERTIFICATE_ERROR"),
								timeout: 0,
								tapToDismiss: true,
								showCloseButton: true
							});
						}
						if (vm.showWindowsDefenderWarning)
							toaster.pop("error", $translate.instant("DEFENDER_WARNING"), "", 0);
						if (Math.abs(vm.secondsOffOfOfficialUtcTime) > 15)
							toaster.pop("warning", $translate.instant("TIME_DESYNC_WARNING"), "", 0);
						if (vm.showDebugLoggingWarning && !hideDebugLogWarning) {
							window.debugLoggingWarningFunction = function () {
								event.stopPropagation();
								localStorage.setItem("hideDebugLogWarning", "True");
								toaster.clear(window.debugLoggingWarning);
								return false;
							}
							window.debugLoggingWarning = toaster.pop({
								type: "warning",
								title: $translate.instant("DEBUG_LOG_WARNING"),
								body: `
								<div style='margin-top: 5px;'>
									<button class='md-button md-primary' onclick='debugLoggingWarningFunction()' style='margin-left: 0;' type='button'>${$translate.instant("DISMISS")}</button>
								</div>
								`,
								timeout: 0,
								bodyOutputType: 'trustedHtml'
							});
						}
						if (vm.showDetailedProtocolLogWarning && !hideDetailedLogWarning) {
							window.detailedProtocolLogWarningFunction = function () {
								event.stopPropagation();
								localStorage.setItem("hideDetailedLogWarning", "True");
								toaster.clear(window.detailedProtocolLogWarning);
								return false;
							}
							window.detailedProtocolLogWarning = toaster.pop({
								type: "warning",
								title: $translate.instant("DETAILED_PROTOCOL_LOG_WARNING"),
								body: `
                                <div style='margin-top: 5px;'>
                                    <button class='md-button md-primary' style='margin-left: 0;' onclick='detailedProtocolLogWarningFunction()' type='button'>${$translate.instant("DISMISS")}</button>
                                </div>
								`,
								timeout: 0,
								bodyOutputType: 'trustedHtml'
							});

						}
						if (vm.showEASAllocationWarning)
							toaster.pop("error", $translate.instant("EAS_ALLOCATION_WARNING"), "", 0);
						if (vm.showOverLicenseLimit)
							toaster.pop("error", $translate.instant("LICENSE_WARNING_LIMITS_EXCEEDED"), "", 0);
						$rootScope.showWarnings = true;
					}
                }, 500);

			}
		}

		function onFeaturesLoaded(result) {
			var newItems = result.data.newFeatures;
			if (newItems.length > 0) {
				$rootScope.$broadcast("user-settings:changed");
				if (newItems.length > 4 && window.innerWidth > 736) {
					$mdDialog.show({
						locals: { items: newItems },
						controller: "whatsNewDialogController",
						controllerAs: "ctrl",
						templateUrl: "~/interface/app/shared/modals/whats-new-double.dlg.html",
						clickOutsideToClose: false
					}).then(function () { });
				}
				else {
					$mdDialog.show({
						locals: { items: newItems },
						controller: "whatsNewDialogController",
						controllerAs: "ctrl",
						templateUrl: "~/interface/app/shared/modals/whats-new-narrow.dlg.html",
						clickOutsideToClose: false
					}).then(function () { });
				}
			}
		}

		function restore(ev, type) {
			$mdDialog
				.show({
					controller: "editDomainRestoreController",
					controllerAs: "ctrl",
					locals: { options: { type:type } },
					templateUrl: "app/sysadmin/manage/domains/modals/restore.dlg.html",
					targetEvent: ev,
					clickOutsideToClose: false
				})
				.then(function () {
                    if (type === 0 || type === 3)
						refresh();
				}, function () { });
		}

		function refresh() {
			vm.checkboxes.reset();
			vm.tableParams.reload();
		}

		function searchItems() {
			vm.searchParams.search = vm.searchText;
			refresh();
		}

		function queryData(params) {
			vm.searchParams.skip = (params.page() - 1) * params.count();
			vm.searchParams.take = params.count();
			vm.searchParams.includeErrored = true;
			
			vm.searchParams.sortField = null;
			for (var k in params.sorting()) {
				if (!params.sorting().hasOwnProperty(k) || !params.sorting()[k])
					continue;
				vm.searchParams.sortField = k;
				vm.searchParams.sortDescending = params.sorting()[k] === "desc";
				break;
			}

			$rootScope.spinner.show();
			return $http
				.post("~/api/v1/settings/sysadmin/domain-list-search", JSON.stringify(vm.searchParams))
				.then(onSearchSuccess, function() {})
				.finally($rootScope.spinner.hide);

			function onSearchSuccess(result) {
				vm.searchResults = result.data.results;
				vm.searchResults.forEach(x => {
					if (vm.detachingDomainNames.includes(x.name)) x.detaching = true;
				});
				vm.searchResultCount = result.data.totalCount;
				params.total(vm.searchResultCount);

				return vm.searchResults;
			}
		}

		function newDomain(ev) {
			$rootScope.spinner.show();
			var promises = [
				$http.get("~/api/v1/settings/sysadmin/domain-path"),
				$http.get("~/api/v1/settings/sysadmin/global-mail")
			];
			$q.all(promises)
				.then(onDefaultSettingsLoaded, errorHandling.report)
				.finally($rootScope.spinner.hide);

			function onDefaultSettingsLoaded(result) {
				var defaultPath = result[0].data.result;
				var defaultHostname = result[1].data.globalMailSettings.domainDefaultSettings.hostname;

				$mdDialog
					.show({
						locals: { defaultPath: defaultPath, defaultHostname: defaultHostname },
						controller: "domainManageNewDomainController",
						controllerAs: "ctrl",
						templateUrl: "app/sysadmin/manage/domains/modals/new-domain.dlg.html",
						targetEvent: ev
					})
					.then(onModalSuccess, function () { });

				function onModalSuccess(modalSuccess) {
					editDomain({ name: modalSuccess.name, idnName: modalSuccess.idnName }, ev, true);
				}
			}
		}

		function deleteItems(items, ev) {
			var count = vm.checkboxes.selectedCount;
			if (!count) {
				errorHandling.warn("ERROR_NO_CARDS");
				return;
			}

			var confirm = $mdDialog.confirmDeletion()
				.textContent($translate.instant("CONFIRMATIONS_DELETE_ITEMS", { items: count }))
				.targetEvent(ev);

			$mdDialog
				.show(confirm)
				.then(onConfirm);

			function onConfirm() {
				var promises = [];
				for (var k in vm.checkboxes.items) {
					if (vm.checkboxes.items.hasOwnProperty(k) && vm.checkboxes.items[k])
						promises.push($http.post("~/api/v1/settings/sysadmin/domain-delete/" + k + "/true"));
				}

				$rootScope.spinner.show();
				$q.all(promises)
					.then(onDeleteSuccess, errorHandling.report)
					.finally($rootScope.spinner.hide);

				function onDeleteSuccess() {
					refresh();
				}
			}
		}

		function editDomain(domain, ev, isNew) {
			if (domain.detaching)
				return;

			if (domain.isProcessing) {
				var confirm = $mdDialog.confirmDeletion()
					.title($translate.instant("CONFIRMATIONS_DOMAIN_PROCESSING"))
					.textContent($translate.instant("CONFIRMATIONS_CANNOT_MODIFY_PROCESSING_DOMAIN"))
					.ok($translate.instant("OK"))
					.noWarn(true)
					.hideCancel(true);
				$mdDialog.show(confirm);
				return;
			} else if (domain.criticallyErrored) {
				$mdDialog
					.show({
						locals: { domainName: domain.name, domainPath: domain.domainPath },
						controller: "failedToLoadDialogController",
						controllerAs: "ctrl",
						templateUrl: "app/sysadmin/manage/modals/domain-failed-to-load.dlg.html",
						targetEvent: ev
					})
					.then(function(result) { if (result) refresh(); }, function() {});
				return;
			} 
			$state.go("index.sysadmin-manage.dashboard.domain-specific", { id: domain.name, idn: domain.idnName, isNew: isNew ? true : null });
		}

		function attachDomain(ev) {
			$mdDialog
				.show({
					controller: "attachDomainController",
					controllerAs: "ctrl",
					templateUrl: "app/sysadmin/manage/domains/modals/attach.dlg.html",
					targetEvent: ev,
					clickOutsideToClose: false
				})
				.then(refresh, function () { });
		}

		function enableDomain(ev, shouldEnable) {
			var count = vm.checkboxes.selectedCount;
			if (!count) {
				errorHandling.warn("ERROR_NO_CARDS");
				return;
			}

			var confirm = $mdDialog.confirm()
				.title($filter("translate")(shouldEnable ? "ENABLE_DOMAIN" : "DISABLE_DOMAIN"))
				.textContent($translate.instant(shouldEnable ? "CONFIRMATIONS_ENABLE_DOMAIN" : "CONFIRMATIONS_DISABLE_DOMAIN", { count: count }))
				.targetEvent(ev)
				.ok($filter("translate")(shouldEnable ? "ENABLE" : "DISABLE"))
				.cancel($filter("translate")("CANCEL"));

			$mdDialog
				.show(confirm)
				.then(onConfirm, function () { });

			async function onConfirm() {
				var promises = [];
				for (var k in vm.checkboxes.items) {
					if (vm.checkboxes.items.hasOwnProperty(k) && vm.checkboxes.items[k]) {
						vm.detachingDomainNames.push(k);
						let parameters = { domainSettings: {isEnabled: !!shouldEnable} };
						promises.push($http.post("~/api/v1/settings/sysadmin/domain-settings/" + k + "/", JSON.stringify(parameters)));
					}
				}

				var toast = toaster.pop({
					type: 'info',
					title: null,
					body: $translate.instant('DOMAIN_MANAGEMENT_ALTER_DOMAIN_RUNNING'),
					timeout: 0,
					bodyOutputType: 'trustedHtml'
				});
				refresh();

				$q.all(promises)
					.then(successHandling.report, errorHandling.report)
					.finally(() => {
						toaster.clear(toast);
						vm.detachingDomainNames = [];
						refresh();
					});
			}
		}

		function reloadDomain(ev) {
			var count = vm.checkboxes.selectedCount;
			if (!count) {
				errorHandling.warn("ERROR_NO_CARDS");
				return;
			}

			var confirm = $mdDialog.confirm()
				.title($filter("translate")("RELOAD_DOMAIN"))
				.textContent($translate.instant("CONFIRMATIONS_RELOAD_DOMAIN", { count: count }))
				.targetEvent(ev)
				.ok($filter("translate")("RELOAD"))
				.cancel($filter("translate")("CANCEL"));

			$mdDialog
				.show(confirm)
				.then(onConfirm, function() {});

			function onConfirm() {
				var promises = [];
				for (var k in vm.checkboxes.items) {
					if (vm.checkboxes.items.hasOwnProperty(k) && vm.checkboxes.items[k]) {
						vm.detachingDomainNames.push(k);
						promises.push($http.post("~/api/v1/settings/sysadmin/reload-domain/" + k));
					}
				}
				var toast = toaster.pop({
					type: 'info',
					title: null,
					body: $translate.instant('DOMAIN_MANAGEMENT_RELOAD_DOMAIN_RUNNING'),
					timeout: 0,
					bodyOutputType: 'trustedHtml'
				});
				refresh();
				$q.all(promises)
					.then(
						successHandling.report,
						errorHandling.report
					)
					.finally(() => {
						toaster.clear(toast);
						vm.detachingDomainNames = [];
						refresh();
					});

			}
		}

		function detachDomain(ev) {
			var count = vm.checkboxes.selectedCount;
			if (!count) {
				errorHandling.warn("ERROR_NO_CARDS");
				return;
			}

			var confirm = $mdDialog.confirm()
				.title($filter("translate")("DETACH_DOMAIN"))
				.textContent($translate.instant("CONFIRMATIONS_DETACH_DOMAIN", { count: count }))
				.targetEvent(ev)
				.ok($filter("translate")("DETACH"))
				.cancel($filter("translate")("CANCEL"));

			$mdDialog
				.show(confirm)
				.then(onConfirm, function () { });

			function onConfirm() {
				var promises = [];
				for (var k in vm.checkboxes.items) {
					if (vm.checkboxes.items.hasOwnProperty(k) && vm.checkboxes.items[k]) {
						vm.detachingDomainNames.push(k);

						promises.push($http.post("~/api/v1/settings/sysadmin/detach-domain/" + k));
					}
				}
				var toast = toaster.pop({
					type: 'info',
					title: null,
					body: $translate.instant('DOMAIN_MANAGEMENT_DETACH_DOMAIN_RUNNING'),
					timeout: 0,
					bodyOutputType: 'trustedHtml'
				});
				refresh();
				$q.all(promises)
					.then(
						successHandling.report,
						errorHandling.report
					)
					.finally(() => {
						toaster.clear(toast);
						vm.detachingDomainNames = [];
						refresh();
					});
			}

		}

		function contextMenuGridItem(item, ev) {
			if (!ev || (ev.type !== "touchstart" && ev.type !== "touchend" && ev.which !== 3) || item.criticallyErrored) {
				return;
			}
			ev.stopPropagation();
			ev.preventDefault();

			var items = vm.checkboxes.getCheckedItems();
			//If we right clicked on a not selected item we want to use that item instead
			if ((items.length > 1 && !_.some(items, function (val) { return val === item.name })) || items.length <= 1) {
				vm.checkboxes.reset();
				vm.checkboxes.checkCheckbox(ev, item);
				items = [item.name];
			}

			$scope.dropdownEvent = $.extend(true, {}, ev);
			$scope.dropdownOptions = [
				{ key: "enableDomain", click: (event) => enableDomain(event, true), translateKey: "ENABLE_DOMAIN" },
				{ key: "disableDomain", click: (event) => enableDomain(event, false), translateKey: "DISABLE_DOMAIN" },
				{ key: "reloadDomain", click: reloadDomain, translateKey: "RELOAD_DOMAIN" },
				{ divider: true },
				{ key: "detachDomain", click: detachDomain, translateKey: "DETACH_DOMAIN" },
				{ divider: true },
				{ key: "deleteItems", click: deleteItems, translateKey: "DELETE" }
			];

			var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
			var element = $("#context-menu-area");
			if (element) {
				var elementCompiled = $compile(elementToCompile)($scope);
				element.append(elementCompiled);
			}
		}

		function exportCsv(ev) {
			var httpPath = "~/api/v1/settings/sysadmin/export-domains-list";
			var params = JSON.stringify({ active: $translate.instant("ENABLED"), inactive: $translate.instant("DISABLED") });
			var fileName = "domains.csv";

			$rootScope.spinner.show();
			coreDataFileStorage
				.downloadFile(httpPath, fileName, params)
				.then(function () { }, errorHandling.report)
				.finally($rootScope.spinner.hide);
		}

		function exportUsers(ev) {
			var httpPath = "~/api/v1/settings/sysadmin/export-users";
			var fileName = $translate.instant("USERS") + ".csv";

			$rootScope.spinner.show();
			coreDataFileStorage
				.downloadFile(httpPath, fileName, {})
				.then(function () { }, errorHandling.report)
				.finally($rootScope.spinner.hide);
		}

		function massEmail(ev) {
			$mdDialog
				.show({
					locals: {
						from: "System Administrator",
						to: $translate.instant("USER"),
						subject: "",
						emailText: "",
						isReminder: false
					},
					controller: "domainMassEmailController",
					controllerAs: "ctrl",
					templateUrl: "app/sysadmin/manage/domains/modals/mass-email.dlg.html",
					targetEvent: ev
				})
				.then(onSuccess);

			function onSuccess(modalSuccess) {
				var params = "";
				switch (modalSuccess.toOption.value) {
					case 0:
					case 2:
						params = JSON.stringify({ recipientType: modalSuccess.toOption.value, subject: modalSuccess.subject, from: modalSuccess.from, toHeader: modalSuccess.toFriendly, body: modalSuccess.emailText, html: true });
						break;
					case 1:
						params = JSON.stringify({ recipientType: modalSuccess.toOption.value, subject: modalSuccess.subject, from: modalSuccess.from, toHeader: modalSuccess.toFriendly, body: modalSuccess.emailText, emailAddresses: [modalSuccess.domainName], html: true });
						break;
					case 3:
						var addresses = modalSuccess.toEmail.split(";");
						for (var i = 0; i < addresses.length; ++i) { addresses[i].trim(); }
						params = JSON.stringify({ recipientType: modalSuccess.toOption.value, subject: modalSuccess.subject, from: modalSuccess.from, toHeader: modalSuccess.toFriendly, body: modalSuccess.emailText, emailAddresses: addresses, html: true });
						break;
				}

				$rootScope.spinner.show();
				$http
					.post("~/api/v1/settings/sysadmin/send-mass-email", params)
					.then(onSendSuccess, errorHandling.report)
					.finally($rootScope.spinner.hide);

				function onSendSuccess() {
					successHandling.report();
				}
			}
		}

		function massReminder(ev) {
			$mdDialog
				.show({
					locals: {
						from: "System Administrator",
						to: $translate.instant("USER"),
						subject: "",
						emailText: "",
						isReminder: true
					},
					controller: "domainMassEmailController",
					controllerAs: "ctrl",
					templateUrl: "app/sysadmin/manage/domains/modals/mass-email.dlg.html",
					targetEvent: ev
				})
				.then(onSuccess);

			function onSuccess(modalSuccess) {
				var params = "";
				switch (modalSuccess.toOption.value) {
					case 0:
					case 2:
						params = JSON.stringify({ recipientType: modalSuccess.toOption.value, subject: modalSuccess.subject, from: modalSuccess.from, body: modalSuccess.emailText, html: true });
						break;
					case 1:
						params = JSON.stringify({ recipientType: modalSuccess.toOption.value, subject: modalSuccess.subject, from: modalSuccess.from, body: modalSuccess.emailText, emailAddresses: [modalSuccess.domainName], html: true });
						break;
					case 3:
						var addresses = modalSuccess.toEmail.split(";");
						for (var i = 0; i < addresses.length; ++i) { addresses[i].trim(); }
						params = JSON.stringify({ recipientType: modalSuccess.toOption.value, subject: modalSuccess.subject, from: modalSuccess.from, body: modalSuccess.emailText, emailAddresses: addresses, html: true });
						break;
				}

				$rootScope.spinner.show();
				$http
					.post("~/api/v1/settings/sysadmin/send-reminder", params)
					.then(onReminderSuccess, errorHandling.report)
					.finally($rootScope.spinner.hide);

				function onReminderSuccess() {
					successHandling.report();
				}
			}
		}

		$scope.$on("signalR.mailHub.client.domainReload", function () { vm.tableParams.reload(); });
	}

})();