(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("importLdapUsersController", importLdapUsersController);

    function importLdapUsersController($rootScope, $scope, $mdDialog, $http, $filter, errorHandling, userDataService) {

        $scope.isBindingValid = false;
		$scope.ldapBinding = "";
		$scope.ldapUser = "";
		$scope.ldapPass = "";
		$scope.emailAsUsername = false;
		$scope.ldapUsers = [];
		$scope.selectedUsers = {};
		var allUsersSelected = false;
		var smUsers = [];

		// Functions
		$scope.cancel = cancel;
		$scope.listUsers = listUsers;
		$scope.importUsers = importUsers;
		$scope.toggleAllUsers = toggleAllUsers;
		$scope.isUserSelected = isUserSelected;

		activate();

		///////////////////////////

		function activate() {
			$http
				.get("~/api/v1/settings/domain/domain")
				.then(onDomainSettingsLoaded, errorHandling.report);

			//$http
			//	.get("~/api/v1/settings/domain/list-users")
			//	.then(onDomainUsersLoaded, errorHandling.report);

			function onDomainSettingsLoaded(result) {
				$scope.ldapBinding = result.data.domainSettings.ldapDomain;
				$scope.authProvider = 
					$scope.adEnabled = result.data.domainSettings.activeDirectoryIntegration;
				$scope.authProviders =
					result.data.domainSettings.authenticationProviders.map(p => ({
						"id": p.providerId,
						"name": p.providerName,
						"type": p.providerType,
						"label": toLabel( p.providerType, p.providerName)
					}));
				if (!$scope.authProviders) 
					$mdDialog.cancel();
				$scope.authProvider = $scope.authProviders[0];
				function toLabel(type, name) {
					let prefix = "";
					switch (type) {
					case 0:
						prefix = $filter('translate')("SMARTERMAIL");
						break;
					case 1:
						prefix = $filter('translate')("ACTIVE_DIRECTORY");
						break;
					case 3:
						prefix = $filter('translate')("LDAP");
						break;
					default:
						prefix = $filter('translate')("UNKNOWN");
						break;
					}

					return name ? `${prefix}: ${name}` : prefix;
				}
			}

			//function onDomainUsersLoaded(result) {
			//	smUsers = result.data.userData;
			//}
			$scope.$watch("authProvider", function (newValue, oldValue) {
				if (newValue === oldValue) return;
				$scope.ldapUsers = [];
				$scope.authRequired = false;
				$scope.ldapUser = null;
				$scope.ldapPass = null;
			});
		}

		function cancel(ev) {
			$mdDialog.cancel();
		}

        function listUsers(ev) {
            $rootScope.spinner.show();
            const input = $scope.authRequired ? 
	            { ldapProviderId: $scope.authProvider.id,ldapUsername: $scope.ldapUser, ldapPassword: $scope.ldapPass } 
	            : { ldapProviderId: $scope.authProvider.id };
			$http
				.post("~/api/v1/settings/domain/ldap-users", JSON.stringify(input))
				.then(onSuccess, onError);

            function onSuccess(success) {

	            $scope.ldapUsers = success.data.contacts.reduce((acc, user) => {
		            acc.push({
			            id: user.guid || user.username,
			            username: user.username,
			            emailAddress: user.email || "",
			            displayName: user.displayAs
		            });
		            return acc;
	            }, []);				
				$scope.ldapUsers = $filter("orderBy")($scope.ldapUsers, "displayName");
				$scope.isBindingValid = true;
                $rootScope.spinner.hide();
            }

            function onError(result) {
	            if (result.data.message === "LDAP_ANONYMOUS_QUERIES_NOT_ALLOWED") {
		            $scope.authRequired = true;
	            } else {
		            errorHandling.report(result);
					$scope.isBindingValid = false;
	            }
                $rootScope.spinner.hide();
			}
		}

		function importUsers(ev) {
			if (!isUserSelected()) return;

			var newUsers = [];

			for (var key in $scope.selectedUsers) {
				if (!$scope.selectedUsers.hasOwnProperty(key) || $scope.selectedUsers[key] === false)
					continue;

				var user, username;
				if (!$scope.emailAsUsername) {
					username = (/[^@]+/).exec(key)[0];
					user = $.grep($scope.ldapUsers, function (u) { return u.username === username; })[0];
				} else {
					user = $.grep($scope.ldapUsers, function (u) { return u.username.toUpperCase() === key.toUpperCase(); })[0];
					if (!user) continue;
					username = user.emailAddress.split('@')[0];
				}
				if (!username) continue;

				var smUser = $.grep(smUsers, function (u) { return u.userName.toUpperCase() === username.toUpperCase(); })[0];
				if (smUser) continue;

				newUsers.push({
					userData: {
						securityFlags: {
							authProviderId: $scope.authProvider.id,
							authType: $scope.authProvider.type
						},
						fullName: user.displayName || "",
						userName: username,
						adUsername: user.username,
						domain: userDataService.user.domain
					},
					userMailSettings: {
						userContactInfo: user
					}
				});
			}
			if (newUsers.length > 0) {
				$http
					.post("~/api/v1/settings/domain/users-put", JSON.stringify({ newUsers: newUsers }))
					.then($mdDialog.hide, errorHandling.report);
			}
		}

		function toggleAllUsers(ev) {
			allUsersSelected = !allUsersSelected;
			if (allUsersSelected) {
				$scope.selectedUsers = {};
				for (var i = 0; i < $scope.ldapUsers.length; ++i) {
					$scope.selectedUsers[$scope.ldapUsers[i].id] = true;
				}
			} else {
				$scope.selectedUsers = {};
			}
		}

		function isUserSelected() {
			for (var key in $scope.selectedUsers) {
				if ($scope.selectedUsers.hasOwnProperty(key) && $scope.selectedUsers[key] === true)
					return true;
			}
			return false;
		}
	}

})();
