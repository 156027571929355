(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("combinedGatewayController", combinedGatewayController);

    function combinedGatewayController($rootScope, $state, $stateParams, $http, $q, $translate, $timeout, $mdDialog, $filter, errorHandling, successHandling) {

        // Variables
        var vm = this;
        vm.encryptionsEnum = [
            { index: 0, label: $translate.instant("NONE"), isSsl: false, isTls: false },
            { index: 1, label: $translate.instant("SSL"), isSsl: true, isTls: false },
            { index: 2, label: $translate.instant("TLS"), isSsl: false, isTls: true }
        ];


        var gateways, gateway;
        vm.id = $stateParams.id || null;
        vm.mode = $stateParams.mode || 0;
		vm.isInitialized = false;
		vm.isReadOnly = false;



		//functions
        vm.save = save;
        vm.deleteThis = deleteThis;

        vm.showDomains = showDomains;
        vm.onSmarterMailModeChanged = onSmarterMailModeChanged;
        vm.onAddDomainClicked = onAddDomainClicked;
        vm.onDomainBlur = onDomainBlur;
        vm.onDomainChanged = onDomainChanged;
        vm.onRemoveDomainClicked = onRemoveDomainClicked;
        vm.onModeChanged = onModeChanged;


        activate();

		///////////////////

        function activate() {
			if (vm.id) {
				vm.isReadOnly = true;
				$rootScope.spinner.show();

				if (vm.mode == 0 || vm.mode == 1) {
					var promises = [$http.get("~/api/v1/settings/sysadmin/gateway/" + vm.id), $http.get("~/api/v1/settings/sysadmin/gateway-domains/" + vm.id)];
					$q.all(promises)
						.then(onOutboundGatewaysLoaded, onOutboundGatewaysFailure)
						.finally($rootScope.spinner.hide);


				}
				else if (vm.mode == 2 || vm.mode == 3) {
					$http
						.get("~/api/v1/settings/sysadmin/smart-host-servers")
						.then(onInboundGatewaysLoaded, errorHandling.report)
						.finally($rootScope.spinner.hide);
				}
			}
			else {
				vm.isReadOnly = false;
				gateway = {};
				vm.mode = gateway.mode = 0;
				vm.isEnabled = gateway.isEnabled = true;
				vm.outAddress = gateway.address = "";
				vm.inAddress = gateway.address = "";
				vm.ipRange = gateway.ipRange = {
					addressFamily: 2,
					ipAddressLow: {
						address: "",
						addressFamily: 0
					},
					ipAddressHigh: {
						address: "",
						addressFamily: 0
					}
				};
				vm.domainsToForward = gateway.domainsToForward = [""];
				vm.smarterMailPassword = gateway.smarterMailPassword = "";
				vm.smarterMailUsername = gateway.smarterMailUsername = "";
				vm.smarterMailMode = gateway.smarterMailMode = false;
				vm.webServiceUrl = gateway.webServiceUrl = "";
				vm.domainVerifyType = gateway.domainVerifyType = 1;
				vm.noAction = gateway.noAction = { type: 0, value: "" };
				vm.lowAction = gateway.lowAction = { type: 0, value: "" };
				vm.medAction = gateway.medAction = { type: 0, value: "" };
				vm.highAction = gateway.highAction = { type: 0, value: "" };
				vm.verifyType = gateway.verifyType = 0;
				vm.isRange = gateway.isRange = false;
				vm.description = gateway.description = "";

				vm.port = gateway.port = "";
				vm.shouldAuthenticate = gateway.shouldAuthenticate = false;
				vm.authUsername = gateway.authUsername = "";
				vm.authPassword = gateway.authPassword = "";
				vm.encryptionType = gateway.encryptionType = 0;
				vm.encryption = vm.encryptionsEnum[vm.encryptionType];
				vm.domainsCount = 0;
				vm.isSmarterMailServer = gateway.isSmarterMailServer = false;
				vm.smUrl = gateway.smUrl = "";
				vm.smUserName = gateway.smUserName = "";
				vm.smPassword = gateway.smPassword = "";
				vm.isInitialized = true;
				vm.verifyConnection = true;
			}

			function onOutboundGatewaysLoaded(result) {
				gateway = result[0].data.gatewayConfig;
				vm.outAddress = gateway.address;
				vm.port = gateway.port;
				vm.shouldAuthenticate = gateway.shouldAuthenticate;
				vm.authUsername = gateway.authUsername;
				vm.authPassword = gateway.authPassword;
				vm.encryptionType = gateway.encryptionType;
				vm.encryption = vm.encryptionsEnum[vm.encryptionType];
				vm.description = gateway.description;
				vm.domains = result[1].data.results;
				vm.domainsCount = vm.domains.length;
				vm.isSmarterMailServer = gateway.isSmarterMailServer;
				vm.smUrl = gateway.smUrl;
				vm.smUserName = gateway.smUserName;
				vm.smPassword = gateway.smPassword;
				vm.isEnabled = gateway.isEnabled;
				vm.isInitialized = true;
				vm.verifyConnection = gateway.verifyConnection;
				vm.mode = gateway.type;
			}

			function onInboundGatewaysLoaded(result) {
				vm.isInitialized = true;
				gateways = result.data.smartHosts || [];
				gateway = $.grep(gateways, function (g) { return g.id === vm.id; });
				if (gateway.length > 0) {
					gateway = gateway[0];
					vm.enabled = gateway.enabled;
					vm.inAddress = gateway.address;
					vm.ipRange = verifyIpRangeExists(gateway.ipRange);
					vm.domainsToForward = gateway.domainsToForward || [];
					vm.domainsToForward.push("");
					vm.smarterMailPassword = gateway.smarterMailPassword;
					vm.smarterMailUsername = gateway.smarterMailUsername;
					vm.smarterMailMode = gateway.smarterMailMode;
					vm.webServiceUrl = gateway.webServiceUrl;
					vm.domainVerifyType = gateway.domainVerifyType;
					vm.noAction = gateway.noAction;
					vm.lowAction = gateway.lowAction;
					vm.medAction = gateway.medAction;
					vm.highAction = gateway.highAction;
					vm.verifyType = gateway.verifyType;
					vm.isRange = gateway.isRange;
					vm.smtpVerification = gateway.verifyType === 2;
					vm.description = gateway.description || "";
					vm.mode = gateway.type + 2;
				} else {
					vm.isInitialized = false;
					errorHandling.report("ERROR");
					$state.go("^", { data: { type: "GATEWAYS" } });
				}
			}

			function onOutboundGatewaysFailure() {
				vm.isInitialized = false;
				errorHandling.report("ERROR");
				$state.go("^", { data: { type: "GATEWAYS" } });
			}
        }

		function save(ev, form) {
			if (form.$invalid) {
				errorHandling.report("CORRECT_INVALID_FIELDS");
				return;
			}

			if (vm.mode == 0 || vm.mode == 1) {
				var outboundType = vm.mode;
				var params = JSON.stringify({
					gatewayConfig: {
						id: vm.id,
						address: vm.outAddress,
						port: vm.port,
						shouldAuthenticate: vm.shouldAuthenticate,
						authUsername: vm.authUsername,
						authPassword: vm.authPassword,
						encryptionType: vm.encryption.index,
						type: outboundType,
						description: vm.description,
						isSmarterMailServer: vm.isSmarterMailServer,
						smUrl: vm.smUrl,
						smUserName: vm.smUserName,
						smPassword: vm.smPassword,
						isEnabled: vm.isEnabled,
						verifyConnection: vm.verifyConnection
					}
				});
				if (vm.verifyConnection && vm.isEnabled) {
					testGateway(params, form);

				} else {
					if (vm.id) {
						editGateway(params, form);
					} else {
						addGateway(params, form);
					}
				}
			}
			else if (vm.mode == 2 || vm.mode == 3) {
				var inboundType = vm.mode - 2;

				checkAddress();
				var verifyType = vm.verifyType;
				if (!vm.smarterMailMode && vm.smtpVerification) {
					verifyType = 2;
				}
				var editedGateway = {
					id: vm.id,
					type: inboundType,
					enabled: vm.enabled,
					isRange: vm.isRange,
					address: vm.inAddress,
					ipRange: vm.ipRange,
					verifyType: verifyType,
					smarterMailMode: vm.smarterMailMode,
					domainVerifyType: vm.domainVerifyType,
					noAction: vm.noAction,
					lowAction: vm.lowAction,
					medAction: vm.medAction,
					highAction: vm.highAction,
					domainsToForward: vm.domainsToForward.filter(function (d) { if (d) { return true; } return false; }),
					webServiceUrl: vm.webServiceUrl,
					smarterMailUsername: vm.smarterMailUsername,
					smarterMailPassword: vm.smarterMailPassword,
					description: vm.description
				};

				var params = {};
				if (vm.id) {
					gateways = $.grep(gateways, function (g) { return g.id !== vm.id; });
					gateways.push(editedGateway);
					params.smartHosts = gateways;
				} else {
					params.toAdd = [editedGateway];
				}
				params = JSON.stringify(params);

				$rootScope.spinner.show();
				$http
					.post("~/api/v1/settings/sysadmin/smart-host-servers", params)
					.then(function () { onSaveSuccess(form); }, errorHandling.report)
					.finally($rootScope.spinner.hide);
			}

			function checkAddress() {
				if (vm.inAddress.indexOf("-") > 0) {
					var split = vm.inAddress.split("-");
					vm.ipRange.ipAddressLow.address = split[0];
					vm.ipRange.ipAddressHigh.address = split[1];
					vm.isRange = true;
				} else {
					vm.isRange = false;
				}
			}

			function onSaveSuccess(form) {
				form.$setPristine();
				$state.go("^", { data: { type: "GATEWAYS" } });
			}
		}

		function deleteThis(event, form) {
			if (!vm.id) {
				$state.go("^");
				return;
			}

			var confirm = $mdDialog.confirmDeletion()
				.textContent($filter('translate')('CONFIRMATIONS_DELETE_ITEMS', { items: 1 }))
				.targetEvent(event);

			$mdDialog.show(confirm).then(onDoDelete);

			function onDoDelete() {
				if (vm.mode === 0 || vm.mode === 1) {
					var params = JSON.stringify({ ids: [vm.id] });
					$http.post("~/api/v1/settings/sysadmin/gateways/delete", params)
						.then(function () {
							$state.go("^");
						}, errorHandling.report);
				} else if (vm.mode === 2 || vm.mode === 3) {
					var params = JSON.stringify({ toRemove: [vm.id] });
					$http.post("~/api/v1/settings/sysadmin/smart-host-servers", params)
						.then(function () {
							$state.go("^");
						}, errorHandling.report);
				}
			}
		}

		function onModeChanged() {
			if (vm.mode === 2) {
				vm.isRange = false;
			}
			if (vm.mode == 3) {
				vm.smtpVerification = false;
				vm.enabled = true;
			}
        }

		function verifyIpRangeExists(range) {
			if (range) return range;
			return {
				addressFamily: 2,
				ipAddressLow: {
					address: "",
					addressFamily: 0
				},
				ipAddressHigh: {
					address: "",
					addressFamily: 0
				}
			};
		}

		function onSmarterMailModeChanged() {
			$timeout(function () {
				if (!vm.smarterMailMode) {
					vm.verifyType = 0;
					if (vm.domainVerifyType === 0)
						vm.domainVerifyType = 1;
				}
			});
		}

		function onAddDomainClicked() {
			if (vm.addDomain) {
				vm.domainsToForward.push(vm.addDomain);
				vm.addDomain = "";
				$timeout(function () { $("#addDomain").trigger("focus"); });
			}
		}

		function onDomainChanged(last, index, form) {
			var domain = form["domain" + index].$viewValue;
			vm.domainsToForward[index] = domain;
			if (domain && domain.length > 0 && last)
				vm.domainsToForward.push("");
		}

		function onDomainBlur(last, index, form) {
			var domain = form["domain" + index].$viewValue;
			if ((!domain || domain.length === 0) && !last)
				vm.domainsToForward.splice(index, 1);
		}

		function onRemoveDomainClicked(domain) {
			var index = vm.domainsToForward.indexOf(domain);
			if (index > -1) {
				vm.domainsToForward.splice(index, 1);
				vm.pageForm.$setDirty();
			}
		}

		function delay(ms) {
			return new Promise(resolve => setTimeout(resolve, ms));
		}

		function failureDialog(result, message) {
				vm.smSuccess = false;
				vm.smtpSuccess = false;
				vm.smtpError = message;
				vm.smError = message;
		}

		function testGateway(params, form) {
			if (!params)
				return;
			vm.smSuccess = false;
			vm.smtpSuccess = false;
			vm.smtpError = null;
			vm.smError = null;
			var progress = $mdDialog.stProgressBar()
				.title($translate.instant('VERIFYING_GATEWAY_CONNECTION'))
				.barText($translate.instant("VERIFYING_SMARTERMAIL_CONNECTION"))
				.showCancel(false)
				.enableButtons(false)
				.percentProgress(0)
				.autoClose(true)
				.guid('verify-connection')
				.progressListener('verify-connection.changed');

			$mdDialog.show(progress);

			$http
				.post("~/api/v1/settings/sysadmin/gateway-test-watcher", params)
				.then(
					(success) => vm.smSuccess = true,
					(failure) => failureDialog(false,failure.data.message.toUpperCase())
				)
				.finally(
					() => {
						$rootScope.$broadcast("verify-connection.changed",
							{
								guid: "verify-connection",
								current: 1,
								total: 2,
								totalCount: -1,
								action: "VERIFYING_SMTP_CONNECTION"
							});
						return $http.post("~/api/v1/settings/sysadmin/gateway-test-smtp", params);
					})
				.then(
					(success) => vm.smtpSuccess = true,
					(failure) => failureDialog(false, failure.data.message.toUpperCase())
				)
				.finally(() => {
					$rootScope.$broadcast("verify-connection.changed",
						{
							guid: "verify-connection",
							current: 2,
							total: 2,
							totalCount: -1,
							action: "COMPLETE"
						});

					$mdDialog.hide(progress);
					if (vm.smSuccess && vm.smtpSuccess) {
						if (vm.id) {
							editGateway(params, form);
						} else {
							addGateway(params, form);
						}
						successHandling.report();
					} else {
						form.$setPristine();
					}
				});

		}

		function addGateway(params, form) {
			if (!params) return;

			$rootScope.spinner.show();
			$http
				.post("~/api/v1/settings/sysadmin/gateway-put", params)
				.then(function () { onOutboundSaveSuccess(form); }, errorHandling.report)
				.finally($rootScope.spinner.hide);
		}

		function editGateway(params, form) {
			if (!params) return;

			$rootScope.spinner.show();
			$http
				.post("~/api/v1/settings/sysadmin/gateway", params)
				.then(function () { onOutboundSaveSuccess(form); }, errorHandling.report)
				.finally($rootScope.spinner.hide);
		}

		function onOutboundSaveSuccess(form) {
			form.$setPristine();
			$state.go("^", { data: { type: "GATEWAYS" } });
		}

		function showDomains() {
			$mdDialog.show({
				locals: {
					title: "DOMAINS",
					items: $filter('orderBy')(vm.domains),
					readonly: true
				},
				controller: "listEditDialogController",
				controllerAs: "diagctrl",
				templateUrl: 'app/settings/shared/list-edit-dialog.dlg.html',
				clickOutsideToClose: false
			});
		}

    }

})();