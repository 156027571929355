(function () {
    "use strict";

    /*	REPORTS
        ... and how they work.

        Reports are generated using JSON data sent by the server. (Smartermail.Web/interface/reports)
        This landing page is one of the few hard coded pages and works as a navigation page to user, domain, or system reports.

        User and Domain load report-summary.html. System loads report-system.html which then navigates to dashboard and summary pages of it's own.
        If you need to make changes, look in config.js for the routing. Most of the routing is done through $stateParams and is based off the language string for the report's name.

        Reports-summary.html loads in JSON and generates it's content using report-card.directive.js. 
        The report-card directive then loads multiple instances of another directive, report-card-content.directive.js, to display the individual pieces of data.

        Clicking into a summary card loads report-details.html which creates the charts and tables using it's own report-details.controller.js.
     
     */

    angular
        .module("smartermail")
        .controller("reportsController", reportsController);

    function reportsController($rootScope, $scope, $transitions, $http, $timeout, $stateParams, coreData, coreDataSettings, claimsService, $mdDialog, userDataService, errorHandling, treeState) {
        var vm = this;
        this.isInitialized;

        $scope.driveLetters = [''];
        $scope.treeExpanded = treeState.isExpanded;
        $scope.showUserReports = userDataService.user.domain ? true : false;
        if ($stateParams.source)
            vm.showDomainReports = true;

        vm.isLinux = window.stOS === 'L';
        vm.showDomainReports = userDataService.user.isDomainAdmin && $stateParams.type === 'domain';
        vm.isSysAdmin = coreData.user.isSysAdmin;
        vm.userReports = [
            { title: 'OVERVIEW', sref: 'index.reports.reports-summary({type: \'user\'})' },
            { title: 'DISK_USAGE', sref: 'index.reports.reports-details({type: \'user\', report: \'disk_usage\'})' },
            { title: 'INCOMING_SPAM', sref: 'index.reports.reports-details({type: \'user\', report: \'incoming_spam\'})' },
            { title: 'INCOMING_MESSAGES', sref: 'index.reports.reports-details({type: \'user\', report: \'incoming_messages\'})' },
            { title: 'OUTGOING_MESSAGES', sref: 'index.reports.reports-details({type: \'user\', report: \'outgoing_messages\'})' },
            { title: 'VIRUSES_CAUGHT', sref: 'index.reports.reports-details({type: \'user\', report: \'viruses_caught\'})' }
        ];
        vm.domainReports = [
            { title: 'OVERVIEW', sref: 'index.reports.domain-reports-summary({type: \'domain\'})' },
            { title: 'DISK_USAGE', sref: 'index.reports.domain-reports-details({type: \'domain\', report: \'disk_usage\'})' },
            { title: 'INCOMING_MESSAGES', sref: 'index.reports.domain-reports-details({type: \'domain\', report: \'incoming_messages\'})' },
            { title: 'OUTGOING_MESSAGES', sref: 'index.reports.domain-reports-details({type: \'domain\', report: \'outgoing_messages\'})' },
            { title: 'MESSAGE_BANDWIDTH', sref: 'index.reports.domain-reports-details({type: \'domain\', report: \'message_bandwidth\'})' },
            { title: 'BANDWIDTH_OVERVIEW', sref: 'index.reports.domain-reports-details({type: \'domain\', report: \'bandwidth_overview\'})' },
            { title: 'GREYLISTED_CONNECTIONS', sref: 'index.reports.domain-reports-details({type: \'domain\', report: \'greylisted_connections\'})' },
            { title: 'INCOMING_SPAM', sref: 'index.reports.domain-reports-details({type: \'domain\', report: \'incoming_spam\'})' },
            { title: 'THROTTLED_MESSAGES', sref: 'index.reports.domain-reports-details({type: \'domain\', report: \'throttled_messages\'})' },
            { title: 'SMTP_IN_SESSIONS', sref: 'index.reports.domain-reports-details({type: \'domain\', report: \'smtp_in_sessions\'})' },
            { title: 'SMTP_OUT_SESSIONS', sref: 'index.reports.domain-reports-details({type: \'domain\', report: \'smtp_out_sessions\'})' },
            { title: 'IMAP_SESSIONS', sref: 'index.reports.domain-reports-details({type: \'domain\', report: \'imap_sessions\'})' },
            { title: 'POP_SESSIONS', sref: 'index.reports.domain-reports-details({type: \'domain\', report: \'pop_sessions\'})' },
            { title: 'VIRUSES_CAUGHT', sref: 'index.reports.domain-reports-details({type: \'domain\', report: \'viruses_caught\'})' }
        ];

        // Functions
        activate();

        // Implementation --------------------------------------------------------

        async function activate() {
            $scope.$on("treeState:stateChange", onTreeStateChange);
            $transitions.onSuccess({}, onStateSuccess);
            $http.get("~/api/v1/report/disks").then(disksFetched, function () { });

            try {
                await coreData.init();
                vm.showDomainReports = userDataService.user.isDomainAdmin && $stateParams.type === 'domain';
                vm.isSysAdmin = coreData.user.isSysAdmin;
                //coreDataSettings.changePageReset();
                //if (vm.showDomainReports) {
                //	coreDataSettings.changePageReset();
                //} else {
                //	$state.go("index.reports.reports-summary", { type: "user" });
                //}
                vm.isInitialized = true;
            } catch (err) {
                $rootScope.spinner.hide();
                errorHandling.report(err);
            }
            if (!claimsService.impersonating() && coreDataSettings.userSettings.seenWhatsNew) {
                var keyExist = ("email" in coreDataSettings.userSettings.seenWhatsNew);
                if (keyExist) {
                    var versionOverride = localStorage.getItem("FeatureVersionOverride");
                    var shouldShow = versionOverride === null ? stProductVersion.split('.')[2] > coreDataSettings.userSettings.seenWhatsNew["email"] : true;
                    if (shouldShow) {
                        var route = `~/api/v1/settings/new-features/Reports${versionOverride === null ? "" : "/" + versionOverride}`;
                        $http.get(route).then(onFeaturesLoaded, function () { });
                    }
                } else {
                    $http.get('~/api/v1/settings/new-features/Reports').then(onFeaturesLoaded, function () { });
                }
            }
        }

        function disksFetched(results) {
            $scope.driveLetters = results.data.driveLetters || [];
        }

        function onTreeStateChange(event, data) {
            $scope.treeExpanded = data.expanded;
            $timeout(function () { $(window).trigger('resize'); }, 300);
        }

        function onFeaturesLoaded(result) {
            var newItems = result.data.newFeatures;
            if (newItems.length > 0) {
                $rootScope.$broadcast("user-settings:changed");
                if (newItems.length > 4 && window.innerWidth > 736) {
                    $mdDialog.show({
                        locals: { items: newItems },
                        controller: "whatsNewDialogController",
                        controllerAs: "ctrl",
                        templateUrl: "~/interface/app/shared/modals/whats-new-double.dlg.html",
                        clickOutsideToClose: false
                    }).then(function () { });
                }
                else {
                    $mdDialog.show({
                        locals: { items: newItems },
                        controller: "whatsNewDialogController",
                        controllerAs: "ctrl",
                        templateUrl: "~/interface/app/shared/modals/whats-new-narrow.dlg.html",
                        clickOutsideToClose: false
                    }).then(function () { });
                }
            }
        }

        function onStateSuccess(event) {
            vm.showDomainReports = userDataService.user.isDomainAdmin && $stateParams.type === 'domain';
            if ($stateParams.source)
                vm.showDomainReports = true;
            vm.isSysAdmin = coreData.user.isSysAdmin;
        };

        $scope.navigate = function (type, report) {
            window.location.href = "root#/reports/" + type + '/' + report;
        }
    }
})();
