(function () {
    "use strict";

    angular
        .module('smartermail')
        .component('topMenuBarChat', {
            templateUrl: "app/root/top-menu-bar-chat.component.html",
            controller: topMenuBarController,
            transclude: false,
            bindings: {}
        });

    /* @ngInject */
    function topMenuBarController($rootScope, $scope, $log, $state, $transitions, $http, $mdDialog, $filter, $timeout,
        coreData, userDataService, errorHandling, AuthenticationService, notifications, coreDataMail, claimsService, xmppService, authStorage, treeState, preferencesStorage) {
        var vm = this;
        vm.chatStatus = 'offline';
        vm.currentItem = null;
        vm.treeState = treeState;

        // Functions
        vm.toggleTree = toggleTree;
        vm.navTo = navTo;
        vm.selectContact = selectContact;
        vm.query = querySearch;

        activate();

        /////////////////

        function activate() {
            coreData.init().then(function () {
                if ($(window).width() <= 736)
                    toggleTree();

                $transitions.onStart({}, stateChanged);
                stateChanged();

                userDataService.init();

                hookEvents();

            });
        }

        function hookEvents() {
            $scope.$on("xmpp.property-changed", onXmppPropertyChanged);
            $scope.$on("xmpp.contacts-changed", onXmppContactsChanged);
        }
        function onXmppPropertyChanged(event, args) {
            if (args.hasOwnProperty("status")) {
                $scope.chatStatus = args.status;
                vm.chatStatus = $scope.chatStatus;
            }
            if (args.hasOwnProperty("unreadCount")) {
                $scope.chatUnreadMessages = args.unreadCount;
                vm.chatUnreadMessages = $scope.chatUnreadMessages;
            }
            vm.totalUnread = vm.chatUnreadMessages > 99 ? "99+" : vm.chatUnreadMessages;
            $scope.$evalAsync();
        }

        function onXmppContactsChanged(event, args) {
            if (!args) return;
            vm.contacts = [];
            for (var i = 0; i < args.contactCategories.length; i++) {
                vm.contacts = vm.contacts.concat(args.contactCategories[i].contacts);
            }
        }

        function querySearch(query) {
            var results = [];
            for (var i = 0; i < vm.contacts.length; i++) {
                if (vm.contacts[i].name.toLowerCase().indexOf(query.toLowerCase()) > -1) {
                    results.push(vm.contacts[i]);
                } else if (vm.contacts[i].jid.toLowerCase().indexOf(query.toLowerCase()) > -1) {
                    results.push(vm.contacts[i]);
                }
            }
            return results;
        }

        function stateChanged(trans) {
            var id = trans ? trans.$to().name : $state.current.name;
            angular.forEach(vm.leftItems, function (item) {
                if (item.sref == id)
                    vm.currentItem = item;
            });
        }

        function toggleTree() {
            treeState.toggleTree(true);
        }

        function selectContact(contact) {
            if (!contact) return;
            vm.selectedItem = undefined;
            vm.participantText = undefined;
            $rootScope.$broadcast("xmpp.select-user", { contact: contact });
        }

        function navTo(sref) {
            $state.go(sref);
        }

    }

})();
