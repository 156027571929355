(function () {
    "use strict";

    angular
		.module("smartermail")
		.service("popupService", popupService);

	function popupService() {
		var vm = this;
        var popWidth = 880;
        var popWidthLarge = 1024;
		var popHeight = 600;

		vm.dimensions = {
			get calendar() { // OLD: height = 768, width = 865
				return constructDimensionString(popWidthLarge, popHeight);
			},
			get chat() { // OLD: height = 640, width = 740
				return constructDimensionString(740, 640);
			},
			get connectivity() { // OLD: height = 430, width = 450
				return constructDimensionString(450, 600);
			},
			get contact() { // OLD: height=600, width=860
				return constructDimensionString(popWidth, popHeight);
			},
			get email() { // OLD: height=900, width=900 AND height=750, width=800
				return constructDimensionString(popWidth, popHeight);
			},
			get loginPreview() {
				return constructDimensionString(popWidth, popHeight);
			},
			get meeting() {
				return constructDimensionString(popWidth, popHeight);
			},
			get note() { // OLD: height=600, width=1060
                return constructDimensionString(popWidthLarge, popHeight);
			},
			get notification() { // OLD: height=750, width=800
				return constructDimensionString(popWidth, popHeight);
			},
			get printEmail() { // OLD: height=750, width=800
				return constructDimensionString(popWidth, popHeight);
			},
			get task() { // OLD: height=600, width=1060
                return constructDimensionString(popWidthLarge, popHeight);
			},
			get transcript() { // OLD: height=750, width=800
				return constructDimensionString(popWidth, popHeight);
			},
		};

		function constructDimensionString(width, height) {
			try {
				var availWidth = window.screen.availWidth - 75;
				var availHeight = window.screen.availHeight - 150;
				if (availWidth < width) width = availWidth;
				if (availHeight < height) height = availHeight;
			} catch (exception) { }
			return `width=${width}, height=${height}`;
		}
    }

})();