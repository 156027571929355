(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("systemMessageArchivingRuleModalController", systemMessageArchivingRuleModalController);

	function systemMessageArchivingRuleModalController($rootScope, $scope, $mdDialog, $http, $filter, rule, errorHandling) {
		var vm = this;
		vm.rule = angular.copy(rule);
	    // Functions
		vm.save = save;
		vm.cancel = cancel;
		vm.onInit = function (form) {
			if (vm.rule.disabled)
				form.$setDirty()
		}

		function save(form) {
			if (form.$pending || form.$invalid) return
			var params = JSON.stringify({
				path: vm.rule.archivePath
			});

			$rootScope.spinner.show();
			$http
				.post("~/api/v1/settings/sysadmin/verify-path", params)
				.then(onVerifySuccess, onVerifyError)
				.finally($rootScope.spinner.hide);

			function onVerifySuccess() {

				var rule = {
					archivePath: vm.rule.archivePath,
					domain: vm.rule.domain,
					disabled: false,
					incoming: vm.rule.type == "1" || vm.rule.type == "2",
					outgoing: vm.rule.type == "1" || vm.rule.type == "3",
					monthsToKeep: vm.rule.yearsToKeep * 12
				};

				var params = {
					rules: [rule]
				};

				$rootScope.spinner.show();
				$http
					.post("~/api/v1/settings/sysadmin/domain-archive-rules", JSON.stringify(params))
					.then(onSaveSuccess, errorHandling.report)
					.finally($rootScope.spinner.hide);

				function onSaveSuccess(result) {
					if (result.data.message)
						errorHandling.report(result.data.message);
					else
						$mdDialog.hide();
				}
			}

			function onVerifyError() {
				$scope.messageArchivingForm.archivePath.$setValidity("requiresNoUnc", false);
			}
		};

		function cancel() {
			$mdDialog.cancel();
		};
	}

})();
