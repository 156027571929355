(function () {
    "use strict";
    angular
        .module("smartermail")
        .controller("emailPopoutController", emailPopoutController);

    function emailPopoutController($rootScope, $scope, $http, $timeout, $controller, $mdDialog, $q, $stateParams,
        coreDataMail, userDataService, coreDataSettings, userTimeService, messageData, simpleXmppService,
        errorHandling, authStorage, themesService, emailFunctions, emailNavigation) {

        var vm = this;
        var packet = emailNavigation.parsePopoutPacket($stateParams.packet);

        // State Variables
        messageData.navigationPacket = packet;

        // Functions
        $scope.hideElements = hideElements;
        $scope.printMessage = printMessage;

        activate();

        ///////////////////////////////////

        function activate() {
            themesService.ensureActivated();

            angular.extend(vm, $controller("emailActions", { $scope: $scope, $http: $http, $timeout: $timeout, $mdDialog: $mdDialog, coreDataMail: coreDataMail }));
            $scope.$watch(
                function () { return messageData.message; },
                function () {
                    $timeout(function () {
                        messageData.messageLoaded = (messageData.message.uid != undefined);
                    });
                    $scope.isDraft = messageData.message.isDraft;
                    $scope.isDeleted = messageData.message.isDeleted;
                });

            setTimeout(Tick, 15000);

            $scope.isDeleted = false;
            $scope.folderPath = packet.folder;
            $scope.owner = packet.owner || '';
            vm.skipNavigateConfirm = false;

            $scope.getActiveFolder = () => packet.folder;
            $scope.getActiveFolderOwner = () => packet.owner || '';
            $scope.isPopout = true;
            $scope.spoolMessage = packet.fileName;
            $scope.spoolName = packet.spoolName;
            $scope.isSpoolMessage = $scope.spoolMessage ? true : false;
            $scope.isQuarantineMessage = packet.quarantine == 1 ? true : false;
            $scope.isArchiveMessage = packet.searchGuid ? true : false;
            $scope.isReadOnly = false;

            if (packet.uid != undefined && packet.markRead) {
                emailFunctions.markRead(packet.owner, packet.folder, parseInt(packet.uid));
            }

            // We don't await the next call so that initialization can continue while the page displays
            init();
        }

        async function init() {
            try {
                await userDataService.init(true);
                if (!userDataService.user.isSysAdmin && !userDataService.user.isPrimarySysAdmin) {
                    await Promise.all([coreDataSettings.init(), coreDataMail.init()]);
                    await userTimeService.init();

                    simpleXmppService.init();
                    if (window.onbeforeunload) {
                        window.st_onbeforeunload = windowOnBeforeUnload;
                    } else {
                        window.onbeforeunload = windowOnBeforeUnload;
                    }

                    $scope.$applyAsync();
                }
                $timeout(() => $scope.$broadcast("loadPopout", {}));
            } catch (err) { }
        }

        function windowOnBeforeUnload() {
            simpleXmppService.deleteRegistration();
        }

        async function Tick() {
            const token = await authStorage.getTokenWithPromise();
            if (FroalaEditor && FroalaEditor.INSTANCES && FroalaEditor.INSTANCES.length > 0 && FroalaEditor.INSTANCES[0] && FroalaEditor.INSTANCES[0].opts && FroalaEditor.INSTANCES[0].opts.requestHeaders) {
                FroalaEditor.INSTANCES[0].opts.requestHeaders.Authorization = "Bearer " + token;
            }
            setTimeout(Tick, 15000);
        }

        function hideElements() {
            $scope.elementsHidden = !$scope.elementsHidden;
            $timeout(function () { $(window).trigger("resize"); }, 10);
        }

        async function printMessage() {
            try {
                await emailFunctions.showPrintPopup(messageData.message);
            } catch (err) {
                errorHandling.report(err);
            }
        }
    }
})();



















































/*
 * Congratulations! You've found the Brachiosaur. There are others dinosaurs hidden. Can you find them all?
 * 
    _
  .~q`,
 {__,  \
     \' \
      \  \
       \  \
        \  `._            __.__
         \    ~-._  _.==~~     ~~--.._
          \        '                  ~-.
           \      _-   -_                `.
            \    /       }        .-    .  \
             `. |      /  }      (       ;  \
               `|     /  /       (       :   '\
                \    |  /        |      /       \
                 |     /`-.______.\     |~-.      \
                 |   |/           (     |   `.      \_
                 |   ||            ~\   \      '._    `-.._____..----..___
                 |   |/             _\   \         ~-.__________.-~~~~~~~~~'''
               .o'___/            .o______}


 * 
 */